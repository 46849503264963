<template>
    <Input
        :id="`${rule.id}_${rule.attribute}`"
        type="text"
        :name="rule.attribute"
        label="Search Convo"
        placeholder="Search for a convo"
        default-icon="search"
        :default-icon-size="16"
        :value="inputValue"
        :has-margin-bottom="false"
        autocomplete="off"
        @focus="handleOpenLogicJump"
    />
</template>

<script>
import { ContentType } from '@/store/enums';
import Input from '@/components/forms/Input';

export default {
    name: 'AttributeConvo',
    components: {
        Input,
    },
    props: {
        rule: {
            type: Object,
            required: true,
        },
    },
    computed: {
        inputValue() {
            if (!this.rule.content) {
                return '';
            }
            return `${this.rule.content.channelName} • ${this.rule.content.convoName}`;
        },
    },
    methods: {
        handleOpenLogicJump() {
            this.$emit('openLogicJump', {
                id: this.rule.id,
                value: this.rule.value,
                type: ContentType.Convo,
            });
        },
    },
};
</script>
